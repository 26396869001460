import React from 'react';
import { withStyles, Grid, FormLabel, FormControl, InputLabel, Input, Button, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { materialAPI, adminAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import DynamicTypo from '../parts/DynamicTypo';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        marginRight: '5px'
    },
    userDataLabel: {
        textAlign: 'left',
        paddingTop: '5px',
    },

});

class AdminVideoControl extends React.Component {
    state = {
        generatedUserId: '',
        title: '',
        url: '',
        id: '',
        global: false,
        endusers: [],
        company: null
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });

    };

    handleAddVideo = () => {
        this.setState({ isLoading: true })
        const data = { title: this.state.title, url: this.state.url, global: this.state.global ? 1 : 0 }
        if (!this.state.global) data.generatedUserId = this.state.generatedUserId;
        materialAPI
            .post('/video', data)
            .then(res => {
                this.setState({
                    isLoading: false,
                    id: res.data.id
                })
                this.props.enqueueSnackbar("Video added successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleEditVideo = () => {
        this.setState({ isLoading: true })
        const data = { title: this.state.title, url: this.state.url, global: this.state.global ? 1 : 0 }
        if (!this.state.global) data.generatedUserId = this.state.generatedUserId;
        materialAPI
            .put(`/video/${this.state.id}`, data)
            .then(res => {
                this.setState({
                    isLoading: false
                })
                this.props.enqueueSnackbar("Video edited successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleDeleteVideo = () => {
        this.setState({ isLoading: true })
        materialAPI
            .delete(`/video/${this.state.id}`)
            .then(res => {
                this.setState({
                    isLoading: false,
                    generatedUserId: '',
                    title: '',
                    url: '',
                    id: '',
                    company: null
                })
                this.props.enqueueSnackbar("Video deleted successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleGlobalChange = () => this.setState({ global: !this.state.global })

    componentDidMount() {


        adminAPI
            .get('/end-users-name/')
            .then(res => {
                this.setState({
                    endusers: res.data.end_users
                });
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })





        const obj = queryString.parse(this.props.location.search)
        if (obj.id)
            this.setState({
                generatedUserId: obj.generatedUserId,
                title: obj.title,
                url: obj.url,
                id: obj.id,
                isLoading: false,
                global: obj.global ? JSON.parse(obj.global) : false,
                company: obj.global ? null : {
                    enduser_name: obj.enduser,
                    id: obj.generatedUserId
                }
            })
    }

    render() {
        const { classes } = this.props;

        let url = ''
        if (this.state.url)
            try {
                url = (new URL(this.state.url))
            }
            catch (ex) {
                this.props.enqueueSnackbar('URL is wrong', { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            }

        return (
            <Grid container justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Grid container item spacing={2} xs={10} sm={8} md={6} lg={4} item alignItems="center">
                    <Grid item xs={12}>
                        {this.state.id ?
                            <DynamicTypo size='md' variant='h4'>Edit Video</DynamicTypo> :
                            <DynamicTypo size='md' variant='h4'>Add Video</DynamicTypo>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.global}
                                    onChange={this.handleGlobalChange}
                                    name="global"
                                    color="primary"

                                />
                            }
                            label="Global Document"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <Autocomplete
                            id="end-user-autosuggest"
                            options={this.state.endusers}
                            getOptionLabel={(option) => option.enduser_name}
                            value={this.state.company}
                            hidden={this.state.global}
                            onChange={
                                (event, newValue, reason) => {
                                    if (newValue)
                                        this.setState({
                                            company: newValue,
                                            generatedUserId: newValue.id
                                        })
                                    else
                                        this.setState({
                                            company: null,
                                            generatedUserId: ''
                                        })
                                }
                            }
                            renderInput={(params) => <TextField {...params} label="End User" />}
                        />
                        <FormLabel hidden={this.state.global} className={classes.userDataLabel}>End user id: {this.state.generatedUserId}</FormLabel>
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="component-video-title">Title</InputLabel>
                            <Input id="component-video-title" value={this.state.title} onChange={this.handleChange('title')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="component-video-url">URL</InputLabel>
                            <Input id="component-video-url" value={this.state.url} onChange={this.handleChange('url')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        {this.state.id ?
                            <React.Fragment>
                                <Button variant="contained" color="default" type='submit' className={classes.button} onClick={this.handleEditVideo}>
                                    Edit
                            </Button>
                                <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDeleteVideo}>
                                    Delete
                            </Button>
                            </React.Fragment>
                            :
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleAddVideo}>
                                Add
                            </Button>}
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.url ?
                            <YouTube
                                videoId={queryString.parse(url.search).v}
                                opts={{
                                    height: 'auto',
                                    width: 'auto',
                                    playerVars: {
                                        autoplay: 0
                                    }
                                }}

                            />
                            : null}
                    </Grid>

                </Grid>




            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminVideoControl)));