import React from 'react';
import { withStyles, Grid, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import CustomMaterialTabl from '../wrappers/CustomMaterialTable';
import { genUserAPI } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'left',
    },

});


const userColumns = [
    { title: 'ID', field: 'id', filtering: false },
    { title: 'Email', field: 'email', filtering: false },
    { title: 'Privilege', field: 'privilege', type: "numeric", filtering: false },
    { title: 'Generated User Id', field: 'generatedUserId', type: "numeric", filtering: false },
    { title: 'Edit', field: 'edit', filtering: false },
]


const genUsersCSVCols = ["id", "invoice_no", "reseller_name", "country_name", "enduser_name", "token", "remain_accounts", "account_validity"];
const genUserLicensesCSVCols = ["id", "license_key", "license_type", "license_duration", "product", "product_family", "package"];
const genUsersColumns = [
    { title: 'ID', field: 'id', filtering: false },
    { title: 'Token', field: 'tokenCopy', filtering: false },
    { title: 'Remain Accounts', field: 'remain_accounts', filtering: false },
    { title: 'Customer Name', field: 'enduser_name', filtering: false },
    { title: 'Invoice No.', field: 'invoice_no', filtering: false },
    { title: 'Reseller Name', field: 'reseller_name', filtering: false },
    { title: 'Country Name', field: 'country_name', filtering: false },
    { title: 'Account Validity', field: 'account_validity', filtering: false },
    { title: 'Invoice Download', field: 'download', filtering: false },
    { title: 'Edit', field: 'edit', filtering: false },
]


class AdminUserControl extends React.Component {
    state = {
        users: [],
        genUsers: []
    }


    download = (title, file) => {
        try {
            const data = new Blob([(new Uint8Array(file.data))]);
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', title);
            tempLink.click();
        }
        catch (error) {
            this.props.enqueueSnackbar("Something Wrong", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
        }

    }

    copyToClipboard = (token) => {
        let textField = document.createElement('textarea');
        textField.innerText = token;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }


    handleUserEditAction = (id, email, privilege) => {
        const parsed = queryString.parse('');
        parsed.id = id
        parsed.privilege = privilege
        parsed.email = email
        const stringified = queryString.stringify(parsed);

        this.props.history.push(`/admin/user/control?${stringified}`);
    }

    handleCompanyUserEditAction = (id) => {
        const parsed = queryString.parse('');
        parsed.id = id
        const stringified = queryString.stringify(parsed);

        this.props.history.push(`/admin/generate-user?${stringified}`);
    }
    exportToCSV = (data, colsName, colsName2) => {
        String.prototype.capitalize = function () {
            return this.charAt(0).toUpperCase() + this.slice(1);
        }
        let CsvString = colsName.map(col => col.split('_').join(' ').capitalize()).join(",") + "," +colsName2.map(col => col.split('_').join(' ').capitalize()).join(",") + "\r\n";
        data.forEach(row => {
            colsName.forEach(col => {
                CsvString += (row[col] ? row[col] : '') + ','
            });
            CsvString += "\r\n"
            row.licenses.forEach(license => {
                colsName.forEach(col => {
                    CsvString += ','
                });
                colsName2.forEach(col => {
                    CsvString += (license[col] ? license[col] : '') + ','
                })
                CsvString += "\r\n"
            })
        });
        CsvString = "data:application/csv," + encodeURIComponent(CsvString);
        const tempLink = document.createElement('a');
        tempLink.href = CsvString;
        tempLink.setAttribute('download', "customer accounts.csv");
        tempLink.click();
    }
    componentDidMount() {
        genUserAPI
            .get('')
            .then(res => {

                let users = []
                for (let genUser of res.data) {
                    if (genUser.invoice_file) genUser.download = <IconButton onClick={() => this.download(genUser.invoice_ext, genUser.invoice_file)}><FontAwesomeIcon icon="download" /></IconButton>
                    const token = genUser.token
                    genUser.tokenCopy =
                        <IconButton
                            aria-label="copytocClipboradIcon"
                            onClick={() => this.copyToClipboard(token)}
                        >
                            <FontAwesomeIcon icon="copy" />
                        </IconButton>

                    genUser.edit = <IconButton onClick={() => this.handleCompanyUserEditAction(genUser.id)}><FontAwesomeIcon icon="edit" /></IconButton>

                    for (let user of genUser.users) {
                        user.edit = <IconButton onClick={() => this.handleUserEditAction(user.id, user.email, user.privilege)}><FontAwesomeIcon icon="edit" /></IconButton>
                        users.push(user)
                    }
                }

                this.setState({ users: users, genUsers: res.data })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
    }
    render() {
        const { classes } = this.props;
        console.log(this.state.genUsers)
        return (
            <Grid container justify='center' className={classes.root}>
                <Grid container spacing={2} item alignItems="center" justify='flex-start'>
                    <Grid item xs={12}>
                        <CustomMaterialTabl title='Users' data={this.state.users} columns={userColumns} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} item alignItems="center" justify='flex-start'>
                    <Grid item xs={12}>
                        <CustomMaterialTabl title={<React.Fragment>Customer Accounts <IconButton size="small" onClick={() => this.exportToCSV(this.state.genUsers, genUsersCSVCols, genUserLicensesCSVCols)}><FontAwesomeIcon icon="file-export" /></IconButton></React.Fragment>} data={this.state.genUsers} columns={genUsersColumns} />

                    </Grid>
                </Grid>


            </Grid>
        )

    }
}
export default withRouter(withStyles(styles)(withSnackbar(AdminUserControl)));