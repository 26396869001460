import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import DynamicTypo from '../parts/DynamicTypo';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
});

class Home extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <Grid container alignItems="center" justify='center' className={classes.root}>

                <Helmet>
                    <title>SmartVision | Home</title>
                </Helmet>
                <Grid item xs={12}>
                    <DynamicTypo color="secondary" variant='h4' size='md'> Welcome to <b style={{color: '#004166'}}>SMART</b><b style={{color: '#039be5'}}>VISION</b> Portal </DynamicTypo>
                </Grid>
                
            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(Home)));