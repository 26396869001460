import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        zIndex: '9000 !important',
        '& span': {
            fontFamily: "'Varela Round', 'Ubuntu'",
            textTransform: 'uppercase',
        }
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
});

class MobileDrawer extends React.Component {
    state = {
        dir: 'left',
        categories: []
    };

    render() {
        const { classes } = this.props;
        const sideList = (
            <div className={classes.list}>
                <List>
                    {this.props.upperLinks.map((item, idx) => (
                        <ListItem button to={item.link} component={Link} key={idx} onClick={this.props.clickHandler}>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {this.props.bottomLinks.map((item, idx) => (
                        <ListItem button to={item.link} component={Link} key={idx} onClick={item.onclick? item.onclick : this.props.clickHandler}>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))}
                </List>
            </div>
        );

        return (
            <div>
                <Drawer className={classes.root} anchor={this.state.dir} open={this.props.open} onClose={this.props.closeHandler}>
                    <div
                        tabIndex={0}
                        role="button"
                    >
                        {sideList}
                    </div>
                </Drawer>
            </div>
        );
    }
}

MobileDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileDrawer);