import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

import Typography from '../parts/DynamicTypo';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const styles = (theme) => ({
    root: {
        // padding: `${theme.spacing(12)}px 0px`,
        position: 'relative',
        textAlign: 'center',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    imgLink: {
        '& img': {
            width: 160,
        },
        '& a': {
            textDecorationLine: 'none',
        },
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        border: '1px solid #ccc',
        borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0 0 1px 1px #444',
        },
        transition: '300ms all ease'
    }
});

const MaterialView = props => {
    const { classes } = props;
    return (
        <Grid container alignItems="center" justify='center' className={classes.root}>
            <Helmet>
                <title>SmartVision | Material</title>
            </Helmet>

            <Grid item xs={12}>
                <Typography variant='h4' size='lg'> Material </Typography>
            </Grid>

            <Grid item xs={4} className={classes.imgLink} onClick={() => props.history.push('material/videos')}>
                <VideoLibraryIcon style={{ fontSize: '200px' }} />
                <Typography variant='h6' size='sm'> Videos </Typography>
            </Grid>
            <Grid item xs={4} className={classes.imgLink} onClick={() => props.history.push('material/docs')}>
                <InsertDriveFileIcon style={{ fontSize: '200px' }} />
                <Typography variant='h6' size='sm'> Documents </Typography>
            </Grid>
        </Grid>
    )
}

export default withRouter(withStyles(styles)(withSnackbar(MaterialView)));