import axios from 'axios';
import Cookies from 'universal-cookie';
import globalVariables from '../global-variables';
import { initUser } from '../store/actions/user';
import store from '../store/store';
import { openPopup } from '../store/actions/site';

const cookies = new Cookies();

export const baseURL = globalVariables.SERVER_BASE_URL;
export const apiURL = baseURL + "api/";

const instances = []

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
}
export const userAPI = axios.create({
    baseURL: `${apiURL}user/`,
    headers: headers
});
instances.push(userAPI)

export const loginAPI = axios.create({
    baseURL: `${apiURL}login/`,
    headers: headers
});
export const logoutAPI = axios.create({
    baseURL: `${apiURL}logout/`,
    headers: headers
});
instances.push(logoutAPI)

export const registerAPI = axios.create({
    baseURL: `${apiURL}register/`,
    headers: headers
});
instances.push(registerAPI)

export const forgetPasswordAPI = axios.create({
    baseURL: `${apiURL}forget-password/`,
    headers: headers
});
instances.push(forgetPasswordAPI)

export const passwordResetAPI = axios.create({
    baseURL: `${apiURL}password-reset/`,
    headers: headers
});
instances.push(passwordResetAPI)

export const verifyEmailAPI = axios.create({
    baseURL: `${apiURL}verify-email/`,
    headers: headers
});
instances.push(verifyEmailAPI)

export const SRAPI = axios.create({
    baseURL: `${apiURL}service-request/`,
    headers: headers
});
instances.push(SRAPI)

export const materialAPI = axios.create({
    baseURL: `${apiURL}material/`,
    headers: headers
});
instances.push(materialAPI)

export const licenseAPI = axios.create({
    baseURL: `${apiURL}license-key/`,
    headers: headers
});
instances.push(licenseAPI)

export const POEAPI = axios.create({
    baseURL: `${apiURL}POE/`,
    headers: headers
});
instances.push(POEAPI)

export const POERequestAPI = axios.create({
    baseURL: `${apiURL}POE-request/`,
    headers: headers
});
instances.push(POERequestAPI)

export const genUserAPI = axios.create({
    baseURL: `${apiURL}generate-user/`,
    headers: headers
});
instances.push(genUserAPI)

export const licenseKeysAPI = axios.create({
    baseURL: `${apiURL}license-keys/`,
    headers: headers
});
instances.push(licenseKeysAPI)

export const POEsAPI = axios.create({
    baseURL: `${apiURL}POEs/`,
    headers: headers
});
instances.push(POEsAPI)

export const adminAPI = axios.create({
    baseURL: `${apiURL}admin/`,
    headers: headers
});
instances.push(adminAPI)

instances.forEach(instance => {
    instance.interceptors.request.use(function (config) {
        config.headers['x-auth-token'] = cookies.get(globalVariables.ACCESS_TOKEN) ? `${cookies.get(globalVariables.ACCESS_TOKEN)}` : '';

        return config;
    }, function (err) {
        return Promise.reject(err);
    });
    instance.interceptors.response.use(function (response) {
        //console.log("response",response)

        return response

    }, function (err) {
        if (err.response) {
            if (err.response.status === 401) {
                if (cookies.get(globalVariables.ACCESS_TOKEN) !== undefined) {
                    cookies.remove(globalVariables.ACCESS_TOKEN, { path: '/' })
                    setTimeout(() => window.location.replace('/'), 1000);
                    store.dispatch(openPopup("Your session has been expired, login again"));
                }

                initUser()

            }
        }

        else if (err.request) {
            store.dispatch(openPopup("Server Connection is lost, try again later", true))
        }
        return Promise.reject(err);
    });
})

