import React from 'react';
import { withStyles, Grid, FormLabel, Button, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { POEAPI, genUserAPI, adminAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import queryString from 'query-string';
import DynamicTypo from '../parts/DynamicTypo';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        marginRight: '5px'
    },
    userDataLabel: {
        textAlign: 'left',
        paddingTop: '5px',
    },
});



class AdminPOEControl extends React.Component {
    state = {
        generatedUserId: null,
        poeFile: {},
        id: null,
        endusers: [],
        company: null
    }


    fileReader = (file) => {
        let reader = new FileReader();
        reader.onloadend = () => {

            this.setState({
                poeFile: file,
            });
        }
        reader.readAsDataURL(file)
    }
    handleFileChange = (event) => {
        this.fileReader(event.target.files[0])
        event.target.value = ""
    }



    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    getGeneratedUserIdInfo = (generatedUserId) => {
        if (generatedUserId)
            genUserAPI
                .get(`/${generatedUserId}`)
                .then(res => {
                    this.setState({
                        company: res.data.enduser_name
                    })
                })
                .catch(err => {
                    this.setState({
                        company: null,
                    })
                })
        else {
            this.setState({
                company: null,
            })
        }
    }
    handleAddPOE = () => {
        this.setState({ isLoading: true })
        let formData = new FormData();
        formData.append('generatedUserId', this.state.generatedUserId);
        formData.append("poeFile", this.state.poeFile);
        POEAPI
            .post('', formData)
            .then(res => {
                this.setState({
                    isLoading: false,
                    id: res.data.id,
                })
                this.props.enqueueSnackbar("POE added successfully", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleEditPOE = () => {
        this.setState({ isLoading: true })
        let formData = new FormData();
        formData.append('generatedUserId', this.state.generatedUserId);
        formData.append("poeFile", this.state.poeFile);
        formData.append("poeFile_ext", this.state.poeFile.name);
        POEAPI
            .put(`/${this.state.id}`, formData)
            .then(res => {
                this.setState({
                    isLoading: false
                })
                this.props.enqueueSnackbar("POE edited successfully", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleDeletePOE = () => {
        this.setState({ isLoading: true })
        POEAPI
            .delete(`/${this.state.id}`)
            .then(res => {
                this.setState({
                    isLoading: false,
                    generatedUserId: null,
                    poe: null,
                    id: null,
                    company: null,
                })
                this.props.enqueueSnackbar("POE deleted successfully", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }

    componentDidMount() {


        adminAPI
            .get('/end-users-name/')
            .then(res => {
                this.setState({
                    endusers: res.data.end_users
                });
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })


        const obj = queryString.parse(this.props.location.search)
        if (obj.id)
            POEAPI
                .get(`/${obj.id}`)
                .then(res => {
                    if (res.data === null) {
                        this.props.enqueueSnackbar("POE doesn't exist.", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        return;
                    }
                    const data = new Blob([(new Uint8Array(res.data.poe_file.data))]);
                    data.name = res.data.poe_ext;

                    this.fileReader(data)
                    this.setState({
                        id: obj.id,
                        isLoading: false,
                        generatedUserId: res.data.generatedUserId,
                        company: {
                            enduser_name: obj.enduser,
                            id: res.data.generatedUserId
                        }

                    })
                })
                .catch(err => {
                    if (err.response)
                        err.response.data.errors.forEach(error => {
                            error.messages.forEach(message => {
                                this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                            })
                        });
                    else
                        this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                })
        this.getGeneratedUserIdInfo(obj.generatedUserId)
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Grid container item spacing={2} xs={10} sm={8} md={6} lg={4} item alignItems="center">
                    <Grid item xs={12}>
                        {this.state.id ?
                            <DynamicTypo size='md' variant='h4'>Edit POE</DynamicTypo> :
                            <DynamicTypo size='md' variant='h4'>Add POE</DynamicTypo>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <Autocomplete
                            id="end-user-autosuggest"
                            options={this.state.endusers}
                            getOptionLabel={(option) => option.enduser_name}
                            value={this.state.company}
                            hidden={this.state.global}
                            onChange={
                                (event, newValue, reason) => {
                                    if (newValue)
                                        this.setState({
                                            company: newValue,
                                            generatedUserId: newValue.id
                                        })
                                    else
                                        this.setState({
                                            company: null,
                                            generatedUserId: null
                                        })
                                }
                            }
                            renderInput={(params) => <TextField {...params} label="End User" />}
                        />
                        <FormLabel hidden={this.state.global} className={classes.userDataLabel}>End user id: {this.state.generatedUserId}</FormLabel>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <input
                            className={classes.input}
                            style={{ display: 'none' }}
                            onChange={this.handleFileChange}
                            id="raised-button-file"
                            type="file"
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" color="primary" component="span" className={classes.button}>
                                Browse
                            </Button>
                        </label>

                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        {this.state.id ?
                            <React.Fragment>
                                <Button variant="contained" color="default" type='submit' className={classes.button} onClick={this.handleEditPOE}>
                                    Edit
                            </Button>
                                <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDeletePOE}>
                                    Delete
                            </Button>
                            </React.Fragment>
                            :
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleAddPOE}>
                                Add
                            </Button>}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography>{this.state.poeFile.name}</Typography>
                        </Grid>
                    </Grid>

                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminPOEControl)));