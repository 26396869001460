import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
    font: {
        fontFamily: 'Varela Round, Ubuntu',
    },
    lg: {
        fontSize: 48,
        [theme.breakpoints.down('md')]: { fontSize: 44 },
        [theme.breakpoints.down('sm')]: { fontSize: 40 },
        [theme.breakpoints.down('xs')]: { fontSize: 36 },
        padding: `${theme.spacing(4)}px 0`,
    },
    md: {
        fontSize: 32,
        [theme.breakpoints.down('md')]: { fontSize: 30 },
        [theme.breakpoints.down('sm')]: { fontSize: 26 },
        [theme.breakpoints.down('xs')]: { fontSize: 22 },
        padding: `${theme.spacing(3)}px 0`,
    },
    sm: {
        fontSize: 28,
        [theme.breakpoints.down('md')]: { fontSize: 26 },
        [theme.breakpoints.down('sm')]: { fontSize: 22 },
        [theme.breakpoints.down('xs')]: { fontSize: 18 },
        padding: `${theme.spacing(2)}px 0`,
    },
    xs: {
        fontSize: 24,
        [theme.breakpoints.down('md')]: { fontSize: 22 },
        [theme.breakpoints.down('sm')]: { fontSize: 18 },
        [theme.breakpoints.down('xs')]: { fontSize: 14 },
        padding: `${theme.spacing(1)}px 0`,
    }
});

const DynamicTypo = props => {
    const {classes, style, variant, size, className} = props;
    return <Typography
            style={style}
            className={
                (size==='lg'? classes.lg : size==='md'? classes.md : classes.sm)
                + ' ' + (classes.font)
                + ' ' + (className? className : '')
            }
            variant={variant} >
        {props.children}
    </Typography>
}

export default withStyles(styles)(DynamicTypo);