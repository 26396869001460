import React from 'react';
import { withStyles, Grid, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import CustomMaterialTabl from '../wrappers/CustomMaterialTable';
import { materialAPI } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'left',
    },

});


const documentsColumns = [
    { title: 'Title', field: 'title', filtering: false },
    { title: 'Customer ID', field: 'generated-user.id', filtering: false },
    { title: 'Customer Name', field: 'generated-user.enduser_name', filtering: false },
    { title: 'Download', field: 'download', filtering: false },
    { title: 'Global', field: 'global', filtering: false },
    { title: 'Edit', field: 'edit', filtering: false },
];


class AdminDocumentDisplay extends React.Component {
    state = {
        documents: [],

    }
    handleEditAction = (id, enduser) => {
        const parsed = queryString.parse('');
        parsed.id = id
        parsed.enduser = enduser
        const stringified = queryString.stringify(parsed);

        this.props.history.push(`/admin/material/document-control?${stringified}`);

    }
    download = (id) => {
        materialAPI
            .get(`/document/blob/${id}`)
            .then(res => {

                const data = new Blob([(new Uint8Array(res.data.file.data))]);
                const csvURL = window.URL.createObjectURL(data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', res.data.file_ext);
                tempLink.click();

            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
        // item.file_ext, item.file
    }

    componentDidMount() {
        materialAPI
            .get('/documents')
            .then(res => {
                // console.log(res.data[0].file)
                res.data.map(item => {

                    item.download = <IconButton onClick={() => this.download(item.id)}><FontAwesomeIcon icon="download" /></IconButton>
                    item.title = item.file_ext
                    item.edit = <IconButton onClick={() => this.handleEditAction(item.id, item.global ? null : item['generated-user'].enduser_name)}><FontAwesomeIcon icon="edit" /></IconButton>
                    
                })
                this.setState({ documents: res.data })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container justify='center' className={classes.root}>
                <Grid container spacing={2} item alignItems="center" justify='flex-start'>
                    <Grid item xs={12}>
                        <CustomMaterialTabl title='Documents' data={this.state.documents} columns={documentsColumns} />
                    </Grid>
                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminDocumentDisplay)));