import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import DynamicTypo from './DynamicTypo';
import { materialAPI } from '../../api/api';
import MyClipLoader from './MyClipLoader';
import Helmet from 'react-helmet';
// import saveAs from 'file-saver';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar': {
            width: 6,
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#004166',
        }
    },
    docContainer: {
        border: '1px solid #ccc',
        flexGrow: 1,
        textAlign: 'center',
    },
    docTitle: {
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: 16,
    },
    fileDiv: {
        '& img': {
            width: 128
        },
        cursor: 'pointer',
    }
});

// const docThumbnail = (data, id) => {

//     const props = { id: id };

//     let loadingTask = pdf.getDocument(data);
//     loadingTask.promise.then(pdf => {
//         //console.log('PDF loaded');

//         // Fetch the first page
//         let pageNumber = 1;
//         pdf.getPage(pageNumber).then(page => {
//             //console.log('Page loaded');

//             let scale = 1.5;
//             let viewport = page.getViewport({scale: scale});

//             // Prepare canvas using PDF page dimensions
//             let canvas = document.getElementById(props.id);
//             let context = canvas.getContext('2d');
//             canvas.height = viewport.height;
//             canvas.width = viewport.width;

//             // Render PDF page into canvas context
//             let renderContext = {
//             canvasContext: context,
//             viewport: viewport
//             };
//             let renderTask = page.render(renderContext);
//             renderTask.promise.then(function () {
//                 // console.log('Page rendered');
//             });
//         });
//     }, function (reason) {
//         // PDF loading error
//         console.error(reason);
//     });
//     return null;
// }

const fileExt = {
    'jpg': { type: 'image/jpeg', icon: '/file-icons/img.png' },
    'jpeg': { type: 'image/jpeg', icon: '/file-icons/img.png' },
    'png': { type: 'image/png', icon: '/file-icons/img.png' },

    'xls': { type: 'application/vnd.ms-excel', icon: '/file-icons/xls.png' },
    'ppt': { type: 'application/vnd.ms-powerpoint', icon: '/file-icons/ppt.png' },
    'doc': { type: 'application/msword', icon: '/file-icons/doc.png' },

    'xlsx': { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: '/file-icons/xls.png' },
    'pptx': { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', icon: '/file-icons/ppt.png' },
    'docx': { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon: '/file-icons/doc.png' },

    'pdf': { type: 'application/pdf', icon: '/file-icons/pdf.png' },
    'txt': { type: 'text/plain', icon: '/file-icons/file.png' },
}

const getExt = filename => {
    let temp = filename.split('.');
    return temp[temp.length - 1];
}

class Documents extends React.Component {

    state = {
        documents: [],
        isLoading: true,
    }

    componentDidMount() {
        materialAPI
            .get('document')
            .then(res => {
                this.setState({ documents: res.data, isLoading: false })
            })
            .catch(err => {

            })
    }

    download = (id) => {
        materialAPI
            .get(`/document/blob/${id}`)
            .then(res => {

                const data = new Blob([(new Uint8Array(res.data.file.data))]);
                const csvURL = window.URL.createObjectURL(data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', res.data.file_ext);
                tempLink.click();

            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })

    }
    render() {

        const { classes } = this.props;
        const { documents } = this.state;

        return (
            <div className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />

                <Helmet>
                    <title>SmartVision | Documents</title>
                </Helmet>

                <Grid item xs={12}>
                    <DynamicTypo variant='h4' size='lg' style={{ textAlign: 'center' }}> Documents </DynamicTypo>
                </Grid>

                {documents.map((doc, idx) =>
                    <Grid className={classes.docContainer} item xs={12} sm={4} md={3} lg={3}>
                        <DynamicTypo size="xs" variant='h5' className={classes.docTitle}>
                            {doc.file_ext.split('.')[0]}
                        </DynamicTypo>
                        {fileExt[getExt(doc.file_ext)] ?
                            <div
                                className={classes.fileDiv} getExt
                                onClick={() => this.download(doc.id)}>
                                <img src={fileExt[getExt(doc.file_ext)].icon} alt='File' />
                            </div> :
                            <div
                                className={classes.fileDiv}
                                onClick={() => this.download(doc.id)}>
                                <img src={fileExt.txt.icon} alt='File' />
                            </div>
                        }

                    </Grid>
                )}
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(withSnackbar(Documents)));