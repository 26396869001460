import React from 'react';
import { withStyles, Grid, TextField, FormControl, Button, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import { CloudUpload, Close as CloseIcon } from '@material-ui/icons';
import { SRAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import Typography from '../parts/DynamicTypo';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
});

class SRView extends React.Component {
    state = {
        subject: '',
        description: '',
        files: [],
        email: '',
        isLoading: false
    }

    fileReader = (file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                files: [...this.state.files, file],
            });
        }
        reader.readAsDataURL(file)
    }
    handleFileUploadChange = (event) => {
        // this.state.files = [];
        for (let file of event.target.files)
            this.fileReader(file)
        event.target.value = ""
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };


    handleSRSubmit = () => {
        this.setState({ isLoading: true })
        let formData = new FormData();
        formData.append('description', this.state.description);
        formData.append('subject', this.state.subject);
        formData.append('email', this.state.email);
        for (let file of this.state.files) formData.append("file[]", file);
        SRAPI.post('', formData)
            .then(res => {
                this.props.enqueueSnackbar("Service Request Sent Successfully Check your mail for sending confirmation", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                this.setState({ isLoading: false, description: '', subject: '', files: [], email: '' })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

                this.setState({ isLoading: false });
            })
    }

    onFileDelete = (index) => {
        const files = [...this.state.files].filter((value, idx) => idx !== index)
        this.setState({ files: files })
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container alignItems="center" justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />

                <Helmet>
                    <title>SmartVision | SR</title>
                </Helmet>
                <Grid item xs={12}>
                    <Typography variant='h4' size='lg'> Service Request </Typography>
                </Grid>

                <Grid item xs={11} sm={10} md={8} lg={6} >
                    <Grid component="form" container spacing={2} alignItems="center" justify='center' onSubmit={(e) => { e.preventDefault(); this.handleSRSubmit(); }} >
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    id="SR-email"
                                    //label="Email"
                                    type="email"
                                    placeholder="Optional contact email - or account email will be used"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.email}
                                    onChange={this.handleChange('email')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    id="SR-description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    rows={15}
                                    rowsMax={30}
                                    value={this.state.description}
                                    onChange={this.handleChange('description')}

                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                            <input
                                className={classes.input}
                                style={{ display: 'none' }}
                                onChange={this.handleFileUploadChange}
                                id="raised-button-file"
                                type="file"
                                multiple
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" color="primary" component="span" className={classes.button}>
                                    Upload
                                    <CloudUpload className={classes.rightIcon} />
                                </Button>
                            </label>

                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>

                            <Button variant="contained" color="primary" type='submit'>
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            {this.state.files.map((file, idx) => <Typography size="xs" variant='h6' key={idx}>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    // className={classes.close}
                                    onClick={() => this.onFileDelete(idx)}
                                >
                                    <CloseIcon />
                                </IconButton>
                                {file.name}
                            </Typography>)}
                        </Grid>
                    </Grid>

                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(SRView)));