import React from 'react';
import { withStyles, Grid, FormControl, InputLabel, Input, Button, InputAdornment, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { userAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DynamicTypo from '../parts/DynamicTypo';
import queryString from 'query-string';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        marginRight: '5px'
    },
});

class AdminGenerateUserControl extends React.Component {
    state = {
        isLoading: false,
        token: null,
        id: '',
        email: '',
        password: '',
        privilege: '',
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    // handleEditUser = () => {

    //     const { id, email, password, privilege } = this.state;
    //     this.setState({ isLoading: true });

    //     let formData = new FormData();
    //     formData.append('email', email);
    //     formData.append('password', password);
    //     formData.append('privilege', privilege);

    //     console.log('EDIT user: ', formData)
    // genUserAPI
    //     .post('', formData)
    //     .then(res => {
    //         this.setState({
    //             token: res.data.token,
    //             isLoading: false
    //         })
    //     })
    //     .catch(err => {
    //         this.setState({
    //             isLoading: false
    //         })
    //         if (err.response)
    //             err.response.data.errors.forEach(error => {
    //                 error.messages.forEach(message => {
    //                     this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
    //                 })
    //             });
    //         else
    //             this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

    //     })
    // }

    andAllStates = () => {
        const { email, password, privilege } = this.state;
        return email && password && privilege;
    }

    componentDidMount() {
        const obj = queryString.parse(this.props.location.search)
        this.setState({
            id: obj.id,
            email: obj.email,
            privilege: obj.privilege,
            isLoading: false,
        })
    }

    handleEditUser = () => {
        this.setState({
            isLoading: true,
        });
        const data = {
            email: this.state.email,
            password: this.state.password,
            privilege: this.state.privilege,
        }
        userAPI
            .put(`/${this.state.id}`, data)
            .then(res => {
                this.setState({
                    isLoading: false,
                    token: null,
                    id: '',
                    email: '',
                    password: '',
                    privilege: '',
                })
                this.props.enqueueSnackbar("User updated successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                    token: null,
                    id: '',
                    email: '',
                    password: '',
                    privilege: '',
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }

    handleDeleteUser = () => {
        this.setState({
            isLoading: true,
        });
        userAPI
            .delete(`/${this.state.id}`)
            .then(res => {
                this.setState({
                    isLoading: false,
                    token: null,
                    id: '',
                    email: '',
                    password: '',
                    privilege: '',
                })
                this.props.enqueueSnackbar("User deleted successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                    token: null,
                    id: '',
                    email: '',
                    password: '',
                    privilege: '',
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }


    render() {
        const { classes } = this.props;
        return (
            <Grid container justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Grid container component="form" spacing={2} xs={12} md={8} item alignItems="center" justify='center' >
                    <Grid item xs={12}>
                        <DynamicTypo size='md' variant="h4">User Control</DynamicTypo>
                    </Grid>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-email">Email</InputLabel>
                        <Input id="component-email" value={this.state.email} onChange={this.handleChange('email')} />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-privilege">Privilege</InputLabel>
                        <Input id="component-privilege" value={this.state.privilege} onChange={this.handleChange('privilege')} />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-password">Password</InputLabel>
                        <Input required type="password" id="component-password" value={this.state.password} onChange={this.handleChange('password')} />
                    </FormControl>

                    <Grid item style={{ textAlign: 'left' }}>

                        <Button variant="contained" color="default" className={classes.button} onClick={this.handleEditUser} disabled={!this.andAllStates()}>
                            Update
                        </Button>
                        <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDeleteUser}>
                            Delete
                        </Button>

                    </Grid>
                    <Grid item xs={10}>
                        {this.state.invoiceFile ? this.state.invoiceFile.name : null}
                    </Grid>
                    <Grid item xs={10}>
                        {this.state.token ? <React.Fragment>
                            <DynamicTypo variant='h4' size='xs' style={{ fontSize: 20, }}>Generated Token:</DynamicTypo>
                            <Input
                                id="URL_textField"
                                multiline
                                className={classes.textField}
                                margin="normal"
                                value={this.state.token}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="copytocClipboradIcon"
                                            onClick={this.copyToClipboard}
                                        >
                                            <FontAwesomeIcon className={classes.icon} icon="copy" />

                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </React.Fragment> : null
                        }
                    </Grid>
                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminGenerateUserControl)));