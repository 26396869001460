import React from 'react';
import { withStyles, Grid, Typography, Button, FormControl, Input, InputLabel, } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { verifyEmailAPI } from '../../api/api';
import { initUser } from '../../store/actions/user';
import Cookies from 'universal-cookie';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';
import DynamicTypo from './DynamicTypo';
import MyClipLoader from './MyClipLoader';

const cookies = new Cookies();
const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

class VerifyEmailForm extends React.Component {
    state = {
        isLoading: true,
        message: ''
    }
    componentDidMount = () => {

        const token = queryString.parse(this.props.location.search).token
        const data = {
            token: token
        }
        verifyEmailAPI.post('', data)
            .then(res => {
                this.props.enqueueSnackbar("Email verified successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                this.props.history.push('/login')
                this.props.handleInitUser()
              
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    render() {

        const { classes } = this.props;
        return (
            <Grid container spacing={2} alignItems="center" justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Helmet>
                    <title>SmartVision | Verify Email</title>
                </Helmet>
                <Grid item xs={12}>
                    <DynamicTypo size="md" variant='h4'> Verify Email</DynamicTypo>
                </Grid>
            </Grid>
        );
    }
}



const mapDispatchToProps = dispatch => {
    return {
        handleInitUser: () => dispatch(initUser()),
    }
}


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(withSnackbar(VerifyEmailForm))));