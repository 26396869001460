import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import YouTube from 'react-youtube';
import DynamicTypo from './DynamicTypo';
import { materialAPI } from '../../api/api';
import MyClipLoader from './MyClipLoader';
import Helmet from 'react-helmet';

const styles = (theme) => ({
    fieldset: {
        border: '1px solid #dcdcdc',
        flexGrow: 1,
    },
    legend: {
        color: '#7b91ae',
        textTransform: 'uppercase',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar': {
            width: 6,
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#004166',
        }
    },
    card: {
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    videoContainer: {
        flexGrow: 1,
        textAlign: 'center',
    },
    videoThumb: {
        width: '96%',
    },
    videoTitle: {
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 800,
    }
});

class Videos extends React.Component {
    
    state = {
        videos: [],
        isLoading: true,
    }

    componentDidMount = () => {
        materialAPI
            .get('video')
            .then(res => {
                // console.log(res.data)
                this.setState({ videos: res.data, isLoading: false })
            })
            .catch(err => {

            })
    }

    render() {
        const { classes } = this.props;
        const { videos } = this.state;

        return (
            <div className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />

                <Helmet>
                    <title>SmartVision | Videos</title>
                </Helmet>

                <Grid item xs={12}>
                    <DynamicTypo variant='h4' size='lg' style={{textAlign: 'center'}}> Videos </DynamicTypo>
                </Grid>

                {videos.map(video =>
                    <Grid className={classes.videoContainer} item xs={12} sm={6} md={4} lg={4}>
                        <DynamicTypo md variant='h5' className={classes.videoTitle}>
                            {video.title}
                        </DynamicTypo>
                        <YouTube
                            className={classes.videoThumb}
                            videoId={video.url.split('=')[1]}
                            opts={{
                                height: 'auto',
                                width: 'auto',
                                playerVars: { // https://developers.google.com/youtube/player_parameters
                                    autoplay: 0
                                }
                            }}
                        />
                    </Grid>
                )}
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(withSnackbar(Videos)));