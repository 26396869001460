import React from 'react';
import { withStyles, List, ListItem, ListItemText, Drawer, Collapse, IconButton } from '@material-ui/core';
import clsx from 'clsx';

import { withRouter, Link, Switch, Route } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";

import AdminUserDisplay from './AdminUserDisplay';
import AdminUserControl from './AdminUserControl';
import AdminGenerateUserControl from './AdminGenerateUserControl';

import AdminLicenseDisplay from './AdminLicenseDisplay';
import AdminLicenseControl from './AdminLicenseControl';

import AdminPOEDisplay from './AdminPOEDisplay'
import AdminPOEControl from './AdminPOEControl';

import AdminVideoDisplay from './AdminVideoDisplay'
import AdminVideoControl from './AdminVideoControl'

import AdminDocumentDisplay from './AdminDocumentDisplay'
import AdminDocumentControl from './AdminDocumentControl'

import { ExpandLess, ExpandMore, Menu as MenuIcon } from '@material-ui/icons';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
        // display: 'flex'
    },
    drawer: {
        flexShrink: 0,
        width: '160px',
        top: '-130px',
        height: 'calc(100% + 130px)',
    },
    drawerPaperIn: {
        left: '-160px !important'
    },
    drawerPaper: {
        // height: 'calc(100% - 225px)',
        position: 'fixed',
        transition: 'all 400ms ease',
        left: 0,
        width: '160px',
        marginTop: '94px',
        height: 'calc(100% - 130px + 35px)',
        //height: 'calc(100% + 130px)',
        borderBottomRightRadius: 4,
        // backgroundColor: '#fefefe',
        borderRight: '1px solid #06A4AB',
        // CUSTOM SCROLLER
        backgroundColor: '#121E25',
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar': {
            width: 6,
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#004166',
        }
    },
    to: theme.mixins.toolbar,
    link: {
        textDecoration: 'none',
        color: theme.palette.secondary.contrastText,
    },
    nestedItem: {
        paddingLeft: theme.spacing(2.5)
    },
    content: {
        flexGrow: 1,
        padding: `0 ${theme.spacing(2)}px`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '160px',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    groupLabel: {
        textTransform: 'uppercase',
        fontWeight: 800,
    },
    menu: {
        '& span': {
            fontFamily: "'Varela Round', 'Ubuntu'",
            fontSize: 15,
        }
    },
    navigateButton: {
        position: 'fixed',
        cursor: 'pointer',
        zIndex: '1000',

        // border: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: 'none',
        borderBottomRightRadius: 4,
        left: '162px',
        backgroundColor: '#121E25',
        transition: 'all 400ms ease',
        '&:hover': {
            // boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.12)',
            // backgroundColor: '#fefefe'
        }
    },
    navigateIn: {
        left: '0px !important',
    },
    menuButton: {
        margin: '0 2px',
        
        '& svg': {
            fontSize: 25,
        }
    },
});

class Admin extends React.Component {
    state = {
        userExpand: false,
        navigate: false,
    }

    navigateToggle = () => {
        let { navigate } = this.state;
        this.setState({
            navigate: !navigate,
        });
    }

    handleCatClick = (name) =>
        this.setState({ [name]: !this.state[name] });

    render() {
        const { classes } = this.props;
        const { navigate } = this.state;

        return (
            <div className={classes.root}>


                <Helmet>
                    <title>SmartVision | Admin Panel</title>
                </Helmet>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: `${classes.drawerPaper} ${navigate ? classes.drawerPaperIn : ''}`,
                    }}
                    elevation={1}
                    anchor="left"
                    open={true}
                >

                    <div className={`${classes.navigateButton} ${navigate ? classes.navigateIn : ''}`} onClick={this.navigateToggle}>
                        <IconButton edge="start" size="small" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.props.menuButtonHandler}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <List className={classes.menu} >
                        <ListItem button onClick={() => this.handleCatClick('userExpand')}>
                            <ListItemText className={classes.groupLabel} primary={'Users'} />
                            {this.state.userExpand ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.userExpand} timeout="auto" unmountOnExit>

                            <Link to="/admin/user" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Display'} />
                                </ListItem>
                            </Link>
                            <Link to="/admin/generate-user" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Generate'} />
                                </ListItem>
                            </Link>
                        </Collapse>
                    </List>


                    <List className={classes.menu}>
                        <ListItem button onClick={() => this.handleCatClick('licenseExpand')}>
                            <ListItemText className={classes.groupLabel} primary={'Licenses'} />
                            {this.state.licenseExpand ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.licenseExpand} timeout="auto" unmountOnExit>

                            <Link to="/admin/license" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Display'} />
                                </ListItem>
                            </Link>
                            <Link to="/admin/license-control" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Control'} />
                                </ListItem>
                            </Link>
                        </Collapse>
                    </List>


                    <List className={classes.menu}>
                        <ListItem button onClick={() => this.handleCatClick('POEExpand')}>
                            <ListItemText className={classes.groupLabel} primary={'POEs'} />
                            {this.state.POEExpand ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.POEExpand} timeout="auto" unmountOnExit>

                            <Link to="/admin/POE" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Display'} />
                                </ListItem>
                            </Link>
                            <Link to="/admin/POE-control" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Control'} />
                                </ListItem>
                            </Link>
                        </Collapse>
                    </List>


                    <List className={classes.menu}>
                        <ListItem button onClick={() => this.handleCatClick('videoExpand')}>
                            <ListItemText className={classes.groupLabel} primary={'Videos'} />
                            {this.state.videoExpand ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.videoExpand} timeout="auto" unmountOnExit>
                            <Link to="/admin/material/video" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Display'} />
                                </ListItem>
                            </Link>
                            <Link to="/admin/material/video-control" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Control'} />
                                </ListItem>
                            </Link>
                        </Collapse>
                    </List>

                    <List className={classes.menu}>
                        <ListItem button onClick={() => this.handleCatClick('documentExpand')}>
                            <ListItemText className={classes.groupLabel} primary={'Documents'} />
                            {this.state.documentExpand ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.documentExpand} timeout="auto" unmountOnExit>
                            <Link to="/admin/material/document" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Display'} />
                                </ListItem>
                            </Link>
                            <Link to="/admin/material/document-control" className={classes.link}>
                                <ListItem button className={classes.nestedItem}>
                                    <ListItemText primary={'Control'} />
                                </ListItem>
                            </Link>
                        </Collapse>
                    </List>

                </Drawer>

                <main className={clsx(classes.content, {
                    [classes.contentShift]: navigate,
                })}>
                    <Switch>
                        <Route exact path='/admin/user' component={AdminUserDisplay} />
                        <Route exact path='/admin/user/control' component={AdminUserControl} />
                        <Route exact path='/admin/generate-user' component={AdminGenerateUserControl} />
                        <Route exact path='/admin/license' component={AdminLicenseDisplay} />
                        <Route exact path='/admin/license-control' component={AdminLicenseControl} />
                        <Route exact path='/admin/POE' component={AdminPOEDisplay} />
                        <Route exact path='/admin/POE-control' component={AdminPOEControl} />
                        <Route exact path='/admin/material/video' component={AdminVideoDisplay} />
                        <Route exact path='/admin/material/video-control' component={AdminVideoControl} />
                        <Route exact path='/admin/material/document' component={AdminDocumentDisplay} />
                        <Route exact path='/admin/material/document-control' component={AdminDocumentControl} />
                    </Switch>
                </main>



            </div>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(Admin)));