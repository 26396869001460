const globalVariables = {
   

    // VARIABLES
    ACCESS_TOKEN: 'access_token',
    SERVER_BASE_URL: "https://customer.smartvision-me.com/",
    WEB_BASE_URL: "https://customer.smartvision-me.com/",


}

export const upperLinks = [
    {
        title: "link",
        link: '/About',
    },

];

export const logo = '/logo.svg';
export default globalVariables;
