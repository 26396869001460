import React from 'react';
import { withStyles, Grid, Typography, FormControl, InputLabel, Input, Button, InputAdornment, IconButton, Select, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { genUserAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DynamicTypo from '../parts/DynamicTypo';
import queryString from 'query-string';

import { Resellers, maintenancePeriods, products, families, packages, licenseDuration, licenseType, countries } from './_staticData';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },

});

class AdminGenerateUserControl extends React.Component {
    state = {
        id: '',
        isLoading: false,
        token: '',
        enduserName: '',
        country: '',
        reseller: '',
        invoiceNo: '',
        product: '',
        family: '',
        package: '',
        licenseType: '',
        licenseDuration: '',
        maintenancePeriod: '',
        licenseKey: '',
        accountValidity: '',
        invoiceFile: '',
        remain_accounts: 5,
    }


    componentDidMount() {
        const obj = queryString.parse(this.props.location.search)
        this.setState({
            id: obj.id ? obj.id : '',
        })
        if (obj.id) {
            genUserAPI
                .get(`/${obj.id}`)
                .then(res => {
                    const data = new Blob([(new Uint8Array(res.data.invoice_file.data))]);
                    data.name = res.data.invoice_ext;
                    this.fileReader(data)

                    this.setState({
                        token: res.data.token,
                        enduserName: res.data.enduser_name ? res.data.enduser_name : '',
                        country: res.data.country_name ? res.data.country_name : '',
                        reseller: res.data.reseller_name ? res.data.reseller_name : '',
                        invoiceNo: res.data.invoice_no ? res.data.invoice_no : '',
                        product: res.data.product ? res.data.product : '',
                        family: res.data.product_family ? res.data.product_family : '',
                        package: res.data.package ? res.data.package : '',
                        licenseType: res.data.license_type ? res.data.license_type : '',
                        licenseDuration: res.data.license_duration ? res.data.license_duration : '',
                        maintenancePeriod: res.data.maintenance_period ? res.data.maintenance_period : '',
                        licenseKey: res.data.license_key ? res.data.license_key : '',
                        accountValidity: res.data.account_validity ? res.data.account_validity : '',
                        invoiceFileName: res.data.invoice_ext ? res.data.invoice_ext : '',
                        remain_accounts: res.data.remain_accounts ? res.data.remain_accounts : '',
                    })
                })
        }
    }

    handleChange = prop => event => {
        if (prop === 'product')
            this.setState({ family: '', pack: '' });
        else if (prop === 'family')
            this.setState({ pack: '' });

        this.setState({ [prop]: event.target.value });
    };

    handleGenToken = () => {
        if (!(this.state.invoiceFile || this.state.invoiceFileName)) {
            this.props.enqueueSnackbar('You have to upload invoice', { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            return
        }
        this.setState({ isLoading: true });

        let formData = new FormData();
        formData.append('enduser_name', this.state.enduserName);
        formData.append('country_name', this.state.country);
        formData.append('reseller_name', this.state.reseller);
        formData.append('invoice_no', this.state.invoiceNo);
        formData.append('product', this.state.product);
        formData.append('product_family', this.state.family);
        formData.append('package', this.state.package);
        formData.append('license_type', this.state.licenseType);
        formData.append('license_duration', this.state.licenseDuration);
        formData.append('maintenance_period', this.state.maintenancePeriod);

        formData.append('license_key', this.state.licenseKey);
        formData.append('account_validity', this.state.accountValidity);
        if (this.state.invoiceFile) // if not it means you will not send a new file, don't update at database.
            formData.append('invoice_file', this.state.invoiceFile);
        formData.append('remain_accounts', this.state.remain_accounts);
        genUserAPI
            .post('', formData)
            .then(res => {
                this.setState({
                    token: res.data.token,
                    isLoading: false,
                    id: '',
                    isLoading: false,
                    enduserName: '',
                    country: '',
                    reseller: '',
                    invoiceNo: '',
                    product: '',
                    family: '',
                    package: '',
                    licenseType: '',
                    licenseDuration: '',
                    maintenancePeriod: '',
                    licenseKey: '',
                    accountValidity: '',
                    invoiceFile: '',
                    remain_accounts: 5,
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }

    handleEdit = () => {
        if (!(this.state.invoiceFile || this.state.invoiceFileName)) {
            this.props.enqueueSnackbar('You have to upload invoice', { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            return
        }
        this.setState({ isLoading: true });

        let formData = new FormData();
        formData.append('enduser_name', this.state.enduserName);
        formData.append('country_name', this.state.country);
        formData.append('reseller_name', this.state.reseller);
        formData.append('invoice_no', this.state.invoiceNo);

        formData.append('maintenance_period', this.state.maintenancePeriod);
        formData.append('invoice_ext', this.state.invoiceFile.name);

        formData.append('account_validity', this.state.accountValidity);
        if (this.state.invoiceFile) // if not it means you will not send a new file, don't update at database.
            formData.append('invoice_file', this.state.invoiceFile);
        formData.append('remain_accounts', this.state.remain_accounts);
        genUserAPI
            .put(`/${this.state.id}`, formData)
            .then(res => {
                this.setState({
                    token: res.data.token,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    andAllStates = () => {
        const { remain_accounts, enduserName, country, reseller, invoiceNo, product, family, pack, licenseType, licenseDuration, maintenancePeriod, licenseKey } = this.state
        return remain_accounts && enduserName && country && reseller && invoiceNo && product && family && pack && licenseType && licenseDuration && maintenancePeriod && licenseKey;
    }

    copyToClipboard = () => {
        let textField = document.createElement('textarea');
        textField.innerText = this.state.token;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }


    fileReader = (file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                invoiceFile: file,
            });
        }
        reader.readAsDataURL(file)
    }

    handleFileChange = (event) => {

        this.fileReader(event.target.files[0])
        event.target.value = ""
    }

    handleGenUserDelete = () => {
        this.setState({ isLoading: true })
        genUserAPI
            .delete(`/${this.state.id}`)
            .then(res => {
                this.setState({
                    isLoading: false,
                    id: '',
                    isLoading: false,
                    token: '',
                    enduserName: '',
                    country: '',
                    reseller: '',
                    invoiceNo: '',
                    maintenancePeriod: '',
                    accountValidity: '',
                    invoiceFile: '',
                    remain_accounts: 5,

                })
                this.props.enqueueSnackbar("Generated user deleted successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Grid container component="form" spacing={2} xs={12} md={8} item alignItems="center" justify='center' onSubmit={(e) => { e.preventDefault(); this.state.id ? this.handleEdit() : this.handleGenToken(); }}>
                    <Grid item xs={12}>
                        <DynamicTypo size='md' variant="h4">{this.state.id ? "Update" : "Generate"} Company User</DynamicTypo>
                    </Grid>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-invoice-no">Invoice No</InputLabel>
                        <Input id="component-invoice-no" value={this.state.invoiceNo} onChange={this.handleChange('invoiceNo')} />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-Reseller">Reseller Name</InputLabel>
                        <Select

                            labelId="component-Reseller-label"
                            id="component-Reseller"
                            value={this.state.Reseller}
                            onChange={this.handleChange('reseller')}
                            style={{ textAlign: 'left' }}
                        >
                            {Resellers.map(r => <MenuItem value={r.name}>{r.name + ' - ' + r.country}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-enduser-name">End-user Name *</InputLabel>
                        <Input required id="component-enduser-name" value={this.state.enduserName} onChange={this.handleChange('enduserName')} />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-country">Country Name</InputLabel>
                        <Select

                            labelId="component-country-label"
                            id="component-country"
                            value={this.state.country}
                            onChange={this.handleChange('country')}
                            style={{ textAlign: 'left' }}
                        >
                            {countries.map(c => <MenuItem value={c.code}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {this.state.id ? null :
                        <React.Fragment>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-product">Product</InputLabel>
                                <Select

                                    labelId="component-product-label"
                                    id="component-product"
                                    value={this.state.product}
                                    onChange={this.handleChange('product')}
                                    style={{ textAlign: 'left' }}
                                >
                                    {products.map(product => <MenuItem value={product}>{product}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-family">Product Family</InputLabel>
                                <Select
                                    labelId="component-family-label"
                                    id="component-family"
                                    value={this.state.family}
                                    onChange={this.handleChange('family')}
                                    style={{ textAlign: 'left' }}
                                >
                                    {families[this.state.product] ?
                                        families[this.state.product].map((family, idx) =>
                                            <MenuItem value={family}>{family}</MenuItem>)
                                        : null}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-package">Package</InputLabel>
                                <Select
                                    labelId="component-package-label"
                                    id="component-package"
                                    value={this.state.package}
                                    onChange={this.handleChange('package')}
                                    style={{ textAlign: 'left' }}
                                >
                                    {packages[this.state.family] ?
                                        packages[this.state.family].map((pack, idx) =>
                                            <MenuItem value={pack}>{pack}</MenuItem>)
                                        : null}
                                </Select>
                            </FormControl>


                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-licenseType">License Type</InputLabel>
                                <Select
                                    labelId="component-licenseType-label"
                                    id="component-licenseType"
                                    value={this.state.licenseType}
                                    onChange={this.handleChange('licenseType')}
                                    style={{ textAlign: 'left' }}
                                >
                                    {licenseType.map(type => <MenuItem value={type}>{type}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-licenseDuration">License Duration</InputLabel>
                                <Select
                                    labelId="component-licenseDuration-label"
                                    id="component-licenseDuration"
                                    value={this.state.licenseDuration}
                                    onChange={this.handleChange('licenseDuration')}
                                    style={{ textAlign: 'left' }}
                                >
                                    {licenseDuration.map(dur => <MenuItem value={dur}>{dur}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </React.Fragment>
                    }
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-maintenancePeriod">Maintenance Period</InputLabel>
                        <Select
                            labelId="component-maintenancePeriod-label"
                            id="component-maintenancePeriod"
                            value={this.state.maintenancePeriod}
                            onChange={this.handleChange('maintenancePeriod')}
                            style={{ textAlign: 'left' }}
                        >
                            {maintenancePeriods.map(period => <MenuItem value={period}>{period + ' Months'}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {this.state.id ? null :
                        <FormControl fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="component-license-key">License Key</InputLabel>
                            <Input id="component-license-key" value={this.state.licenseKey} onChange={this.handleChange('licenseKey')} />
                        </FormControl>
                    }
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-license-key">Max Accounts *</InputLabel>
                        <Input required type="number" id="component-license-key" value={this.state.remain_accounts} onChange={this.handleChange('remain_accounts')} />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-license-key">Account Validty in Months *</InputLabel>
                        <Input required type="number" id="component-license-key" value={this.state.accountValidity} onChange={this.handleChange('accountValidity')} />
                    </FormControl>

                    <input
                        className={classes.input}
                        style={{ display: 'none' }}
                        onChange={this.handleFileChange}
                        id="raised-button-file"
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" color="default" component="span" className={classes.button}>
                            {this.state.id ? "Update" : "Upload"} Invoice *
                        </Button>
                    </label>

                    <Grid item >
                        <Button variant="contained" color="primary" type='submit'>
                            {this.state.id ? "Update" : "Generate"}
                        </Button>
                    </Grid>

                    {this.state.id ? <Grid item>
                        <Button variant="contained" color="secondary" onClick={this.handleGenUserDelete}>
                            Delete
                        </Button>
                    </Grid> : null}

                    <Grid item xs={10}>
                        {this.state.invoiceFile ?
                            this.state.invoiceFile.name : null
                        }
                    </Grid>
                    <Grid item xs={10}>
                        {this.state.token ? <React.Fragment>
                            <DynamicTypo variant='h4' size='xs' style={{ fontSize: 20, }}>Generated Token:</DynamicTypo>
                            <Input
                                id="URL_textField"
                                multiline
                                className={classes.textField}
                                margin="normal"
                                value={this.state.token}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="copytocClipboradIcon"
                                            onClick={this.copyToClipboard}
                                        >
                                            <FontAwesomeIcon className={classes.icon} icon="copy" />

                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </React.Fragment> : null
                        }
                    </Grid>
                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminGenerateUserControl)));