import React from 'react';
import { withStyles, Grid, Button, } from '@material-ui/core';
import { Helmet } from "react-helmet";
import DynamicTypo from './DynamicTypo';

const styles = (theme) => ({
    root: {
        padding: `${theme.spacing(12)}px 0px`,
        position: 'relative',
        textAlign: 'center',
    },
    error: {
        fontSize: 56,
        fontWeight: 800,
    },
    button: {
        marginTop: 12,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});
function NotFound(props) {

    const { classes } = props;
    return (
        <div className={classes.root}>
            <Helmet>
                <title>SmartVision | Not Found</title>
            </Helmet>
            <DynamicTypo style={{padding: 8}} className={classes.error} size="lg" variant="h1">404</DynamicTypo>
            <DynamicTypo style={{padding: 8}} size="md" variant="h4">Page does not exist.</DynamicTypo>
            <DynamicTypo style={{padding: 8}} size="sm" variant="h4">If you think this is an error, please contact us.</DynamicTypo>
            <Button className={classes.button} variant="outlined" color="primary" onClick={() => { props.history.push('/home') }}>
                Home
            </Button>
        </div>
    );
}

export default withStyles(styles)(NotFound);