import * as actionTypes from '../actionTypes';
import Cookies from 'universal-cookie';
import { userAPI, logoutAPI } from '../../api/api'
import globalVariables from '../../global-variables';

const cookies = new Cookies();

const startOperation = () => {
    return {
        type: actionTypes.USER_START_OPERATION,
    }
}
const initUserOperation = (userData) => {
    return {
        type: actionTypes.INIT_USER_OPERATION,
        userData: userData,
    }
}
const completeOperation = () => {
    return {
        type: actionTypes.USER_COMPLETE_OPERATION,
    }
}



export const initUser = () => {

    return dispatch => {
        userAPI.get('')
            .then(res => {
                dispatch(initUserOperation(res.data))
                dispatch(completeOperation())
            })
            .catch(err => {
                cookies.remove(globalVariables.ACCESS_TOKEN, { path: '/' })
                dispatch(initUserOperation({ user: null }))
                dispatch(completeOperation())
            })
    }

}

export const logout = () => {
    return dispatch => {
        dispatch(startOperation())
        logoutAPI.get('').then(res => {
            cookies.remove(globalVariables.ACCESS_TOKEN, { path: '/' })
            dispatch(initUser())
        }).catch(err => {
            cookies.remove(globalVariables.ACCESS_TOKEN, { path: '/' })
            dispatch(initUser())
        })
    }
}