import React from 'react';
import { withStyles, Grid, Button,  IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import { licenseAPI } from '../../api/api';
import CustomMaterialTabl from '../wrappers/CustomMaterialTable';

import { POEAPI } from '../../api/api';
import { POERequestAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import Typography from '../parts/DynamicTypo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    demo: {
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
});

const licenseColumns = [
    { title: 'License Key', field: 'license_key', filtering: false },
    { title: 'Product', field: 'product', filtering: false },
    { title: 'Product Family', field: 'product_family', filtering: false },
    { title: 'Package', field: 'package', filtering: false },
    { title: 'License Type', field: 'license_type', filtering: false },
    { title: 'License Duration', field: 'license_duration', filtering: false },
];



class SRView extends React.Component {
    state = {
        isLoading: true,
        serial_keys: [],
        POE: null
    }

    handlePOERequest = () => {
        POERequestAPI
            .post('')
            .then(res => {
                this.props.enqueueSnackbar("POE Requested Successfully", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    download = (title, file) => {
        const data = new Blob([(new Uint8Array(file.data))]);
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', title);
        tempLink.click();
    }

    componentDidMount() {
        licenseAPI
            .get('')
            .then(res => {
                this.setState({ serial_keys: res.data })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })

        POEAPI
            .get('')
            .then(res => {
                this.setState({ POE: res.data, isLoading: false })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    download_licenses = (filename, keys) => {
       
        const keysText = keys.map(key => `${key.license_key || ''}, ${key.product || ''}, ${key.product_family || ''}, ${key.package || ''}, ${key.license_type || ''}, ${key.license_duration || ''}`).join('\n')

        const element = document.createElement("a");
        const file = new Blob([keysText], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "keys.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container alignItems="center" justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />

                <Helmet>
                    <title>SmartVision | POE</title>
                </Helmet>


                <Grid item xs={11} sm={10} md={8} lg={6} >
                    <Grid container spacing={10} alignItems="center" justify='center' >

                        <Grid item xs={12}>
                            <Typography variant='h4' size='lg'>License Keys</Typography>
                            <div className={classes.demo}>
                                <CustomMaterialTabl title='Licenses' data={this.state.serial_keys} columns={licenseColumns} />
                            </div>
                        </Grid>
                        {this.state.serial_keys.length ?
                            <Button variant="contained" onClick={e => this.download_licenses("keys.txt", this.state.serial_keys)}>Download All Keys</Button>
                            : null
                        }
                        <Grid item xs={12}>
                            {this.state.POE ?
                                <Typography>
                                    POE: {' '}
                                    {this.state.POE.poe_ext}
                                    <IconButton onClick={() => this.download(this.state.POE.poe_ext, this.state.POE.poe_file)}><FontAwesomeIcon icon="download" /></IconButton>
                                </Typography> :
                                <Button variant="contained" color="primary" onClick={this.handlePOERequest}>
                                    Request POE
                                </Button>}
                        </Grid>

                    </Grid>

                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(SRView)));