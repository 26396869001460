import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
        type:'dark',
        primary: {
            main: '#06A4AB',
            contrastText: '#F9F9F9'
        },
        text: {
            primary: '#F9F9F9',
            secondary: '#06A4AB',
        },
        // background:{
        //    paper:'#121E25'
        // }
    }
});
export default responsiveFontSizes(theme);