import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import store from './store/store';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

library.add( fas)

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter >
            <SnackbarProvider
                ref={notistackRef}
                action={(key) => (
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        // className={classes.close}
                        onClick={onClickDismiss(key)}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                maxSnack={10}
            >
                <App />
            </SnackbarProvider>
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
