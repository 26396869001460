import React from 'react';
import Typography from '../parts/DynamicTypo';
import { withStyles } from '@material-ui/core';

import './Footer.css';
const styles = theme => ({
    root: {
        backgroundColor: "#121E25",
        color: theme.palette.secondary.contrastText
    },

});

function Footer(props) {
    const { classes, logo } = props;
    return (
        <div className="footer-wrapper">
            <div className={"social-bar "+classes.root}>
                <div className="col-1-1">

                    <Typography variant='h3' size='md'>Connect with us on social media</Typography>

                    <ul className="social-media-icons">

                        <li>
                            <a href="https://www.facebook.com/smartvisionme" target="_blank">
                                <i className="footer-icon fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/SmartVision_ME" target="_blank">
                                <i className="footer-icon fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/smart-vision-me/?trk=tabs_biz_home" target="_blank">
                                <i className="footer-icon fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li>
                            <a href="tel:+2 025378542 " target="_blank">
                                <i className="footer-icon fas fa-phone"></i>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@spss-me.com" target="_blank">
                                <i className="footer-icon fas fa-envelope"></i>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <footer id="colophon" className={"site-footer "+classes.root} role="contentinfo">

                <div >
                    <div>
                        <div className="site-info">
                            Copyright Smart Vision 2021
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );

}
export default withStyles(styles)(Footer);