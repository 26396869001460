import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initUser, logout } from '../../store/actions/user'

import { logo } from "../../global-variables";

import AppBar from './AppBar';
import Drawer from './MobileDrawer';


class Navbar extends Component {

  state = {
    drawer: false,
  }


  drawerHandler = e => {
    // console.log("CLOSING")
    const { drawer } = this.state;
    this.setState({ drawer: !drawer, });
  }

  render() {

    const { user, logout } = this.props;

    const upperLinks = user? [
      {link: '/home', title: 'Home'},
      {link: '/service-request', title: 'Service Request'},
      {link: '/material', title: 'Material'},
      {link: '/poe', title: 'POE'},
    ] : [ {link: '/home', title: 'Home'} ];

    const bottomLinks = user? user.privilege === 1 || user.privilege === 2 ? [
      {link: '/admin', title: 'Admin'},
      {link: '/home', title: 'Logout', onclick: logout }
    ] : [
      {link: '/home', title: 'Logout', onclick: logout },
    ] : [
      {link: '/login', title: 'Login'},
      {link: '/register', title: 'Register'}
    ];

    
    return (
      <React.Fragment>
        <Drawer
          open={this.state.drawer}
          closeHandler={this.drawerHandler}
          upperLinks={upperLinks}
          bottomLinks={bottomLinks}
          clickHandler={this.drawerHandler}
          user={user}
          logout={logout}
        />
        <AppBar logo={logo} menuButtonHandler={this.drawerHandler} user={user} logout={logout} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
      user: state.user.user,
  }
}
const mapDispatchToProps = dispatch => {
  return {
      handleInitUser: () => dispatch(initUser()),
      logout: () => dispatch(logout()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((Navbar)));