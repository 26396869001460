import React from 'react';
import { withStyles, Grid, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import CustomMaterialTabl from '../wrappers/CustomMaterialTable';
import { POEsAPI } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'left',
    },

});


const poesColumns = [
    { title: 'Download POE', field: 'download', filtering: false },
    { title: 'End User ID', field: 'generated-user.id', filtering: false },
    { title: 'End User Name', field: 'generated-user.enduser_name', filtering: false },
    { title: 'Edit', field: 'edit', filtering: false },
];


class AdminLicenseDisplay extends React.Component {
    state = {
        POEs: [],

    }
    handleEditAction = (id, poe, generatedUserId, enduser) => {
        const parsed = queryString.parse('');
        parsed.id = id
        parsed.generatedUserId = generatedUserId
        parsed.enduser = enduser
        const stringified = queryString.stringify(parsed);

        this.props.history.push(`/admin/POE-control?${stringified}`);

    }
    download = (title, file) => {
        const data = new Blob([(new Uint8Array(file.data))]);
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', title);
        tempLink.click();
    }

    componentDidMount() {
        POEsAPI
            .get('')
            .then(res => {
                res.data.map(item => {
                    item.download = <IconButton onClick={() => this.download(item.poe_ext, item.poe_file)}><FontAwesomeIcon icon="download" /></IconButton>
                    item.edit = <IconButton onClick={() => this.handleEditAction(item.id, item.poe, item.generatedUserId, item['generated-user'].enduser_name)}><FontAwesomeIcon icon="edit" /></IconButton>
                })
                this.setState({ POEs: res.data })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container justify='center' className={classes.root}>
                <Grid container spacing={2} item alignItems="center" justify='flex-start'>
                    <Grid item xs={12}>
                        <CustomMaterialTabl title='POEs' data={this.state.POEs} columns={poesColumns} />
                    </Grid>
                </Grid>



            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminLicenseDisplay)));