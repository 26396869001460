import React from 'react';
import { withStyles, Grid, Typography, Button, FormControl, Input, InputLabel, } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgetPasswordAPI } from '../../api/api';
import { initUser } from '../../store/actions/user';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import DynamicTypo from './DynamicTypo';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

class ForgetPasswordForm extends React.Component {
    state = {
        email: '',
        view: 0,

    }
    handleViewChange = () => {
        const { classes } = this.props;
        switch (this.state.view) {
            case 0:
                return <React.Fragment>
                    <Grid item xs={12}>
                        <DynamicTypo size="md" variant='h4'> Password Reset </DynamicTypo>
                    </Grid>
                    <Grid component="form" container spacing={2} alignItems="center" justify='center' onSubmit={(e) => { e.preventDefault(); this.handleForgetPassword(); }} >
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-email">Email</InputLabel>
                                <Input type="email" id="customer-email" value={this.state.email} onChange={this.handleChange('email')} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>

                            <Button variant="contained" color="primary" type='submit'>
                                Submit
                        </Button>
                        </Grid>

                    </Grid>
                </React.Fragment>
            case 1:
                return <Grid container spacing={2} alignItems="center" justify='center'>

                    <Grid item xs={12}>
                        <Typography> Link to reset your password has been sent to your email. </Typography>
                        <Typography display="inline"> If you didn't get the email </Typography>
                        <Link variant="contained" color="primary" onClick={this.handleForgetPassword}>
                            click to resend.
                        </Link>
                    </Grid>

                </Grid>
            default:
                return <div></div>
        }
    }
    handleForgetPassword = () => {
        const data = {
            email: this.state.email,
        }
        forgetPasswordAPI.post('', data)
            .then(res => {
                this.props.enqueueSnackbar("Check your email to reset your password.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                this.setState({ view: 1 })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })


            })
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    render() {

        const { classes } = this.props;

        return (
            <Grid container spacing={2} alignItems="center" justify='center' className={classes.root}>
                <Helmet>
                    <title>SmartVision | Forget Password</title>
                </Helmet>

                <Grid item xs={10} sm={8} md={6} lg={4} >
                    {this.handleViewChange()}
                </Grid>
            </Grid>
        );
    }
}



const mapDispatchToProps = dispatch => {
    return {
        handleInitUser: () => dispatch(initUser()),
    }
}


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(withSnackbar(ForgetPasswordForm))));