import React from 'react';
import { withStyles, Grid, FormControl, InputLabel, Input, Button, FormLabel, TextField, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { licenseAPI, adminAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import queryString from 'query-string';
import DynamicTypo from '../parts/DynamicTypo';

import { products, families, packages, licenseDuration, licenseType } from './_staticData';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        marginRight: '5px'
    },
    userDataLabel: {
        textAlign: 'left',
        paddingTop: '5px',
    },

});



class AdminLicenseControl extends React.Component {
    state = {
        generatedUserId: '',
        id: '',
        product: '',
        family: '',
        package: '',
        licenseType: '',
        licenseDuration: '',
        licenseKey: '',
        endusers: [],
        company: null
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleAddLicense = () => {
        this.setState({ isLoading: true })
        const data = {
            generatedUserId: this.state.generatedUserId,
            license_key: this.state.licenseKey,
            product: this.state.product,
            product_family: this.state.family,
            package: this.state.package,
            license_type: this.state.licenseType,
            license_duration: this.state.licenseDuration,
        }
        licenseAPI
            .post('', data)
            .then(res => {
                this.setState({
                    isLoading: false
                })
                this.props.enqueueSnackbar("license key added", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleEditLicense = () => {
        this.setState({ isLoading: true })
        const data = {
            generatedUserId: this.state.generatedUserId,
            license_key: this.state.licenseKey,
            product: this.state.product,
            product_family: this.state.family,
            package: this.state.package,
            license_type: this.state.licenseType,
            license_duration: this.state.licenseDuration,
        }
        licenseAPI
            .put(`/${this.state.id}`, data)
            .then(res => {
                this.setState({
                    isLoading: false
                })
                this.props.enqueueSnackbar("license key edited", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleDeleteLicense = () => {
        this.setState({ isLoading: true })
        licenseAPI
            .delete(`/${this.state.id}`)
            .then(res => {
                this.setState({
                    isLoading: false,
                    generatedUserId: '',
                    id: '',
                    product: '',
                    family: '',
                    package: '',
                    licenseType: '',
                    licenseDuration: '',
                    licenseKey: '',
                    company: null,
                })
                this.props.enqueueSnackbar("license key deleted", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }

    componentDidMount() {


        adminAPI
            .get('/end-users-name/')
            .then(res => {
                this.setState({
                    endusers: res.data.end_users
                });
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })



        const obj = queryString.parse(this.props.location.search)
        if (obj.id)
            licenseAPI
                .get(`/${obj.id}`)
                .then(res => {
                    if (res.data === null) {
                        this.props.enqueueSnackbar("license doesn't exist.", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        return;
                    }
                    this.setState({
                        isLoading: false,
                        id: obj.id,
                        licenseKey: res.data.license_key,
                        generatedUserId: res.data.generatedUserId,

                        product: res.data.product,
                        family: res.data.product_family,
                        package: res.data.package,
                        licenseType: res.data.license_type,
                        licenseDuration: res.data.license_duration,

                        company: {
                            enduser_name: obj.enduser,
                            id: res.data.generatedUserId
                        }
                    })
                })
                .catch(err => {
                    if (err.response)
                        err.response.data.errors.forEach(error => {
                            error.messages.forEach(message => {
                                this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                            })
                        });
                    else
                        this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                })

    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Grid container item spacing={2} xs={10} sm={8} md={6} lg={4} item alignItems="center">
                    <Grid item xs={12}>
                        {this.state.id ?
                            <DynamicTypo size='md' variant="h4">Edit License</DynamicTypo> :
                            <DynamicTypo size='md' variant="h4">Add License</DynamicTypo>
                        }
                    </Grid>
                    <FormControl fullWidth className={classes.formControl}>
                        <Autocomplete
                            fullWidth
                            id="end-user-autosuggest"
                            options={this.state.endusers}
                            getOptionLabel={(option) => option.enduser_name}
                            value={this.state.company}
                            hidden={this.state.global}
                            onChange={
                                (event, newValue, reason) => {
                                    if (newValue)
                                        this.setState({
                                            company: newValue,
                                            generatedUserId: newValue.id
                                        })
                                    else
                                        this.setState({
                                            company: null,
                                            generatedUserId: ''
                                        })
                                }
                            }
                            renderInput={(params) => <TextField {...params} label="End User" />}
                        />
                        <FormLabel hidden={this.state.global} className={classes.userDataLabel}>End user id: {this.state.generatedUserId}</FormLabel>
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-product">Product</InputLabel>
                        <Select

                            labelId="component-product-label"
                            id="component-product"
                            value={this.state.product}
                            onChange={this.handleChange('product')}
                            style={{ textAlign: 'left' }}
                        >
                            {products.map(product => <MenuItem value={product}>{product}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-family">Product Family</InputLabel>
                        <Select
                            labelId="component-family-label"
                            id="component-family"
                            value={this.state.family}
                            onChange={this.handleChange('family')}
                            style={{ textAlign: 'left' }}
                        >
                            {families[this.state.product] ?
                                families[this.state.product].map((family, idx) =>
                                    <MenuItem value={family}>{family}</MenuItem>)
                                : null}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-package">Package</InputLabel>
                        <Select
                            labelId="component-package-label"
                            id="component-package"
                            value={this.state.package}
                            onChange={this.handleChange('package')}
                            style={{ textAlign: 'left' }}
                        >
                            {packages[this.state.family] ?
                                packages[this.state.family].map((pack, idx) =>
                                    <MenuItem value={pack}>{pack}</MenuItem>)
                                : null}
                        </Select>
                    </FormControl>


                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-licenseType">License Type</InputLabel>
                        <Select
                            labelId="component-licenseType-label"
                            id="component-licenseType"
                            value={this.state.licenseType}
                            onChange={this.handleChange('licenseType')}
                            style={{ textAlign: 'left' }}
                        >
                            {licenseType.map(type => <MenuItem value={type}>{type}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-licenseDuration">License Duration</InputLabel>
                        <Select
                            labelId="component-licenseDuration-label"
                            id="component-licenseDuration"
                            value={this.state.licenseDuration}
                            onChange={this.handleChange('licenseDuration')}
                            style={{ textAlign: 'left' }}
                        >
                            {licenseDuration.map(dur => <MenuItem value={dur}>{dur}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="component-license-key">License Key</InputLabel>
                        <Input id="component-license-key" value={this.state.licenseKey} onChange={this.handleChange('licenseKey')} />
                    </FormControl>

                    <Grid item xs={12} style={{ textAlign: 'left', padding: '8px 0 0 0' }}>
                        {this.state.id ?
                            <React.Fragment>
                                <Button variant="contained" color="default" type='submit' className={classes.button} onClick={this.handleEditLicense}>
                                    Edit
                            </Button>
                                <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDeleteLicense}>
                                    Delete
                            </Button>
                            </React.Fragment>
                            :
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleAddLicense}>
                                Add
                            </Button>}
                    </Grid>

                </Grid>



            </Grid >
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminLicenseControl)));