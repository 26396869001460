import React from 'react';
import { withStyles, Grid, Checkbox, Typography, Button, FormLabel, FormControlLabel, IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { materialAPI, adminAPI } from '../../api/api';
import MyClipLoader from '../parts/MyClipLoader';
import queryString from 'query-string';
import { CloudUpload, Close as CloseIcon } from '@material-ui/icons';

import DynamicTypo from '../parts/DynamicTypo';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        marginRight: '5px'
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    userDataLabel: {
        textAlign: 'left',
        paddingTop: '5px',
    },
});



class AdminDocumentControl extends React.Component {
    state = {
        id: '',
        files: [],
        file: {},
        generatedUserId: '',
        global: false,
        countUploaded: 0,
        endusers: [],
        company: null
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleGlobalChange = () => this.setState({ global: !this.state.global })

    onFileDelete = (index) => {
        const files = [...this.state.files].filter((value, idx) => idx !== index)
        this.setState({ files: files })
    }

    
    handleUploadDocument = () => {
        this.setState({ isLoading: this.state.files.length > 0 })
        this.state.files.forEach(file => {

            let formData = new FormData();

            formData.append('global', this.state.global ? 1 : 0);
            if (!this.state.global)
                formData.append('generatedUserId', this.state.generatedUserId);

            formData.append("file", file);
            materialAPI
                .post('/document', formData)
                .then(res => {
                    this.setState({
                        countUploaded: this.state.countUploaded + 1,
                    }, () => {
                        if ((this.state.countUploaded) === this.state.files.length) {

                            this.setState({
                                id: '',
                                files: [],
                                file: {},
                                generatedUserId: '',
                                global: false,
                                countUploaded: 0,
                                isLoading: false,
                                company: null
                            })
                        }
                    })

                    this.props.enqueueSnackbar(`Document ${file.name} added successfully.`, { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                })
                .catch(err => {
                    this.setState({
                        countUploaded: this.state.countUploaded + 1,
                    }, () => {
                        if ((this.state.countUploaded) === this.state.files.length) {
                            this.setState({
                                id: '',
                                files: [],
                                file: {},
                                generatedUserId: '',
                                global: false,
                                countUploaded: 0,
                                isLoading: false,
                                company: null
                            })
                        }
                    })
                    if (err.response)
                        err.response.data.errors.forEach(error => {
                            error.messages.forEach(message => {
                                this.props.enqueueSnackbar(`Document ${file.name} failed to upload.`, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                            })
                        });
                    else
                        this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

                })

        })

    }
    handleEditDocument = () => {
        this.setState({ isLoading: true })
        let formData = new FormData();

        formData.append('global', this.state.global ? 1 : 0);
        if (!this.state.global)
            formData.append('generatedUserId', this.state.generatedUserId);

        formData.append("file", this.state.file);
        if (this.state.file)
            formData.append("file_name", this.state.file.name);

        materialAPI
            .put(`/document/${this.state.id}`, formData)
            .then(res => {
                this.setState({
                    isLoading: false
                })
                this.props.enqueueSnackbar("Document edited successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    handleDeleteDocument = () => {
        this.setState({ isLoading: true })
        materialAPI
            .delete(`/document/${this.state.id}`)
            .then(res => {
                this.setState({
                    isLoading: false,
                    id: '',
                    file: {},
                    generatedUserId: '',
                    company: null
                })
                this.props.enqueueSnackbar("Document deleted successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }

    fileReader = (file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            if (this.state.id)
                this.setState({
                    file: file,
                });
            else
                this.setState({
                    files: [...this.state.files, file],
                });

        }
        reader.readAsDataURL(file)
    }

    handleFileChange = (event) => {

        for (let file of event.target.files)
            this.fileReader(file)
        event.target.value = ""
    }

    componentDidMount() {
        adminAPI
            .get('/end-users-name/')
            .then(res => {
                this.setState({
                    endusers: res.data.end_users
                });
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
            })



        const obj = queryString.parse(this.props.location.search)
        if (obj.id)
            materialAPI
                .get(`/document/${obj.id}`)
                .then(res => {
                    if (res.data === null) {
                        this.props.enqueueSnackbar("Document doesn't exist.", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        return;
                    }
                    const data = new Blob([(new Uint8Array(res.data.file.data))]);
                    data.name = res.data.file_ext;
                    this.fileReader(data)
                    this.setState({
                        id: obj.id,
                        isLoading: false,
                        generatedUserId: res.data.generatedUserId,
                        global: res.data.global,
                        company: res.data.global? null : {
                            enduser_name: obj.enduser,
                            id: res.data.generatedUserId
                        }
                    })
                })
                .catch(err => {
                    if (err.response)
                        err.response.data.errors.forEach(error => {
                            error.messages.forEach(message => {
                                this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                            })
                        });
                    else
                        this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                })
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container justify='center' className={classes.root}>
                <MyClipLoader isLoading={this.state.isLoading} />
                <Grid container item spacing={2} xs={10} sm={8} md={6} lg={4} alignItems="center">
                    <Grid item xs={12}>
                        {this.state.id ?
                            <DynamicTypo variant='h4' size='md'>Edit Document</DynamicTypo> :
                            <DynamicTypo variant='h4' size='md'>Add Document</DynamicTypo>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.global}
                                    onChange={this.handleGlobalChange}
                                    name="global"
                                    color="primary"

                                />
                            }
                            label="Global Document"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <Autocomplete
                            id="end-user-autosuggest"
                            options={this.state.endusers}
                            getOptionLabel={(option) => option.enduser_name}
                            value={this.state.company}
                            hidden={this.state.global}
                            onChange={
                                (event, newValue, reason) => {
                                    if (newValue)
                                        this.setState({
                                            company: newValue,
                                            generatedUserId: newValue.id
                                        })
                                    else
                                        this.setState({
                                            company: null,
                                            generatedUserId: ''
                                        })
                                }
                            }
                            renderInput={(params) => <TextField {...params} label="End User" />}
                        />
                        <FormLabel hidden={this.state.global} className={classes.userDataLabel}>End user id: {this.state.generatedUserId}</FormLabel>
                    </Grid>



                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <input
                            className={classes.input}
                            style={{ display: 'none' }}
                            onChange={this.handleFileChange}
                            id="raised-button-file"
                            type="file"
                            multiple={!this.state.id}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" color="primary" component="span" className={classes.button}>
                                Browse
                            </Button>
                        </label>

                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        {this.state.id ?
                            <React.Fragment>
                                <Button variant="contained" color="default" type='submit' className={classes.button} onClick={this.handleEditDocument}>
                                    Edit
                            </Button>
                                <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDeleteDocument}>
                                    Delete
                            </Button>
                            </React.Fragment>
                            :
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleUploadDocument}>
                                Upload <CloudUpload className={classes.rightIcon} />
                            </Button>}
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.id ?
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <Typography>{this.state.file.name}</Typography>
                            </Grid>
                            :
                            this.state.files.map((file, idx) => <Typography size="xs" variant='h6' key={idx}>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    // className={classes.close}
                                    onClick={() => this.onFileDelete(idx)}
                                >
                                    <CloseIcon />
                                </IconButton>
                                {file.name}
                            </Typography>)
                        }
                    </Grid>

                </Grid>




            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminDocumentControl)));