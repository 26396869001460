import React from 'react';
import { withStyles, Grid, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import CustomMaterialTable from '../wrappers/CustomMaterialTable';
import { materialAPI } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'left',
    },

});


const videosColumns = [
    { title: 'Title', field: 'title', filtering: false },
    { title: 'URL', field: 'url', filtering: false },
    { title: 'Customer ID', field: 'generated-user.id', filtering: false },
    { title: 'Customer Name', field: 'generated-user.enduser_name', filtering: false },
    { title: 'Global', field: 'global', filtering: false },
    { title: 'Edit', field: 'edit', filtering: false },
];


class AdminVideoDisplay extends React.Component {
    state = {
        videos: [],
    }

    handleEditAction = (id, title, url, generatedUserId, global, enduser) => {
        const parsed = queryString.parse('');
        parsed.id = id
        parsed.title = title
        parsed.url = url
        parsed.generatedUserId = generatedUserId
        parsed.global = global
        parsed.enduser = enduser
        const stringified = queryString.stringify(parsed);

        this.props.history.push(`/admin/material/video-control?${stringified}`);
    }
    
    componentDidMount() {
        materialAPI
            .get('/videos')
            .then(res => {
                res.data.map(item => {
                    item.edit = <IconButton onClick={() => this.handleEditAction(item.id, item.title, item.url, item.generatedUserId, item.global, item.global ? null : item['generated-user'].enduser_name)}><FontAwesomeIcon icon="edit" /></IconButton>
                })
                this.setState({ videos: res.data })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container justify='center' className={classes.root}>
                <Grid container spacing={2} item alignItems="center" justify='flex-start'>
                    <Grid item xs={12}>
                        <CustomMaterialTable title='Videos' data={this.state.videos} columns={videosColumns} />
                    </Grid>
                </Grid>
            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminVideoDisplay)));