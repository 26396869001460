import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingScreen from 'react-loading-screen';
import { initUser } from './store/actions/user'
import Footer from './components/Footer/Footer';
import Navbar from './components/Header/Navbar';
import { ThemeProvider } from '@material-ui/styles';
import theme from './assets/Theme';
import NotFound from './components/parts/NotFound';
import LoginForm from './components/parts/LoginForm';
import RegisterForm from './components/parts/RegisterForm';
import SRView from './components/views/SRView';
import MaterialView from './components/views/MaterialView';
import POEView from './components/views/POEView';
import HomeView from './components/views/HomeView';
import AdminView from './components/views/AdminView';
import ForgetPasswordForm from './components/parts/ForgetPasswordForm';
import VerifyEmailForm from './components/parts/VerifyEmailForm';
import ResetPasswordForm from './components/parts/ResetPasswordForm';
import { logo } from "./global-variables";
import { withStyles } from '@material-ui/core';

import Documents from './components/parts/Documents';
import Videos from './components/parts/Videos';


const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  layout: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(2),
    minHeight: '55vh',
    // backgroundColor: "#121E25",
    color: '#F9F9F9'
  }
});
class ErrorHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errorOccurred: false }
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true })
    // logErrorToMyService(error, info)
  }

  render() {
    return this.state.errorOccurred ? <h1>Something went wrong!</h1> : this.props.children
  }
}
class App extends React.Component {
  state = {
    isLoading: true
  }
  componentDidMount() {
    this.setState({ isLoading: false })
    this.props.handleInitUser()
  }
  render() {
    const { classes } = this.props
    return (
      <ErrorHandler>
        <ThemeProvider theme={theme}>
          <LoadingScreen
            loading={this.props.userIsLoading || this.state.isLoading}
            bgColor="121E25"
            spinnerColor={theme.palette.text.primary}
            textColor={theme.palette.text.primary}
            logoSrc={logo}
          >
            {this.props.userIsLoading || this.state.isLoading ?
              null :

              <div className={classes.root}>

                <Navbar />
                <div className={classes.layout}>
                  <Switch>
                    <Route exact path="/login" render={props => this.props.user !== null ? <Redirect to='/' /> : <LoginForm {...props} />} />
                    <Route exact path="/register" render={props => this.props.user !== null ? <Redirect to='/' /> : <RegisterForm {...props} />} />
                    <Route exact path="/forget-password" render={props => this.props.user !== null ? <Redirect to='/' /> : <ForgetPasswordForm {...props} />} />
                    <Route exact path="/reset-password" render={props => this.props.user !== null ? <Redirect to='/' /> : <ResetPasswordForm {...props} />} />
                    <Route exact path="/verify-email" render={props => this.props.user !== null ? <Redirect to='/' /> : <VerifyEmailForm {...props} />} />

                    <Route exact path="/service-request" render={props => this.props.user === null ? <Redirect to='/login' /> : <SRView {...props} />} />
                    <Route exact path="/material" render={props => this.props.user === null ? <Redirect to='/login' /> : <MaterialView {...props} />} />

                    <Route exact path='/material/videos' component={Videos} />
                    <Route exact path='/material/docs' component={Documents} />

                    <Route exact path="/POE" render={props => this.props.user === null ? <Redirect to='/login' /> : <POEView {...props} />} />

                    <Route exact path='/admin' render={props => this.props.user === null ? <Redirect to='/login' /> :
                      this.props.user.privilege === 0 ? <Redirect to='/' /> : <Redirect to='/admin/user' />} />

                    <Route path="/(admin)/" render={props => this.props.user === null ? <Redirect to='/login' /> :
                      this.props.user.privilege === 0 ? <Redirect to='/' /> : <AdminView {...props} />} />

                    <Route exact path='/(|Home)/' render={() => <HomeView />} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
                <Footer />

              </div>
            }
          </LoadingScreen>
        </ThemeProvider>
      </ErrorHandler>

    );
  }
}


const mapStateToProps = state => {
  return {
    userIsLoading: state.user.isLoading,
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleInitUser: () => dispatch(initUser()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App)));
