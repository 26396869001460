import React from 'react';
import { withStyles, Grid, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import CustomMaterialTabl from '../wrappers/CustomMaterialTable';
import { licenseKeysAPI } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'left',
    },

});


const licenseColumns = [

    { title: 'License Key', field: 'license_key', filtering: false },
    { title: 'Product', field: 'product', filtering: false },
    { title: 'Product Family', field: 'product_family', filtering: false },
    { title: 'Package', field: 'package', filtering: false },
    { title: 'License Type', field: 'license_type', filtering: false },
    { title: 'License Duration', field: 'license_duration', filtering: false },

    { title: 'End User ID', field: 'generated-user.id', filtering: false },
    { title: 'End User Name', field: 'generated-user.enduser_name', filtering: false },
    { title: 'Edit', field: 'edit', filtering: false },
];




class AdminLicenseDisplay extends React.Component {
    state = {
        license_keys: [],

    }
    handleEditAction = (id, enduser) => {
        const parsed = queryString.parse('location.search');
        parsed.id = id
        parsed.enduser = enduser
        const stringified = queryString.stringify(parsed);

        this.props.history.push(`/admin/license-control?${stringified}`);

    }
    componentDidMount() {
        licenseKeysAPI
            .get('')
            .then(res => {
                res.data.map(item => {
                    item.edit = <IconButton onClick={() => this.handleEditAction(item.id, item['generated-user'].enduser_name)}><FontAwesomeIcon icon="edit" /></IconButton>
                })
                this.setState({ license_keys: res.data })
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })

            })
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container justify='center' className={classes.root}>
                <Grid container spacing={2} item alignItems="center" justify='flex-start'>
                    <Grid item xs={12}>
                        <CustomMaterialTabl title='License Keys' data={this.state.license_keys} columns={licenseColumns} />
                    </Grid>
                </Grid>

            </Grid>
        )

    }
}

export default withRouter(withStyles(styles)(withSnackbar(AdminLicenseDisplay)));