import React from 'react';
import { withStyles, Grid, Hidden, Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
    root: {
        // backgroundColor: palette.first,
        alignItems: 'center',
        backgroundColor: "#121E25",
        padding: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flex: '1 0',
        alignItems: "center",
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        padding: `${theme.spacing(1)}px ${theme.spacing(5)}px ${theme.spacing(1)}px 2px`,
    },
    logoLink: {
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        textDecoration: 'none'
    },
    navbarLogo: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        flexBasis: '10%',
    },
    navbarLinks: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexBasis: '60%',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    navbarToggles: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        flexBasis: '20%',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    link: {
        borderRadius: '0',
        color: "white",
        textDecoration: 'none',
        marginLeft: theme.spacing(1.5),
        '&:first-child': {
            marginLeft: theme.spacing(3)
        },
        padding: theme.spacing(1),
        transition: 'all 250ms ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
        borderLeft: '0.5px solid #708c9c',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        '& svg': {
            fontSize: 36,
        }
    },
    bar: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: 8000,
    }
});

class Appbar extends React.Component {
    state = {
        drawer: false,
    }

    drawerHandler = e => {
        const { drawer } = this.state;
        this.setState({ drawer: !drawer, });
    }

    render() {

        const { classes, logo } = this.props;

        return (
            <AppBar position="fixed" color="inherit" className={classes.appBar}>
                <Grid container className={classes.root} >
                    <Hidden mdUp>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.props.menuButtonHandler}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                    <div className={classes.container}>
                        <div className={classes.navbarLogo}>
                            <a className={classes.logoLink} href='http://smartvision-me.com/'>
                                <img style={{ height: "50px" }} src={logo} alt='SmartVision Logo' />
                            </a>
                        </div>
                        {this.props.user ?
                            <React.Fragment>
                                <div className={classes.navbarLinks}>
                                    <Hidden smDown>
                                        <Link className={classes.link} to='/service-request'>Service Request</Link>
                                        <Link className={classes.link} to='/material'>Material</Link>
                                        <Link className={classes.link} to='/POE'>POE</Link>
                                    </Hidden>
                                </div>
                                <div className={classes.navbarToggles}>
                                    <Hidden xsDown>
                                        {this.props.user.privilege == 1 || this.props.user.privilege == 2 ?
                                            <Link to="/admin" className={classes.link}>
                                                Admin
                                            </Link> : null}
                                        <Link className={classes.link} color="inherit" onClick={this.props.logout}>Logout</Link>
                                    </Hidden>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className={classes.navbarLinks}></div>
                                <div className={classes.navbarToggles}>
                                    <Hidden xsDown>
                                        <Button component={Link} to="/login" className={classes.link}>
                                            Login
                                        </Button>
                                        <Link to="/register" className={classes.link}>
                                            Register
                                        </Link>
                                    </Hidden>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </Grid>
            </AppBar>
        );
    }

}

export default withStyles(styles)(Appbar);
