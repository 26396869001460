import React from 'react';
import { withStyles, Grid, Typography, Button, FormControl, Input, InputLabel, } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { passwordResetAPI } from '../../api/api';
import globalVariables from '../../global-variables';
import { initUser } from '../../store/actions/user';
import Cookies from 'universal-cookie';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';
import DynamicTypo from './DynamicTypo';

const cookies = new Cookies();
const styles = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
    },
    button: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

class ResetPasswordForm extends React.Component {
    state = {
        password: '',
        confirmed_password: '',
    }
    handleResetPassword = () => {

        const token = queryString.parse(this.props.location.search).token
        const data = {
            password: this.state.password,
            confirmed_password: this.state.confirmed_password,
            token: token
        }
        passwordResetAPI.post('', data)
            .then(res => {
                cookies.set(globalVariables.ACCESS_TOKEN, res.data['x-auth-token'])
                this.props.enqueueSnackbar("Password was reset successfully.", { variant: 'success', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                this.props.handleInitUser()
            })
            .catch(err => {
                if (err.response)
                    err.response.data.errors.forEach(error => {
                        error.messages.forEach(message => {
                            this.props.enqueueSnackbar(message, { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })
                        })
                    });
                else
                    this.props.enqueueSnackbar("Can't connect to server, try again later!!!", { variant: 'error', /*persist: true,*/ autoHideDuration: 4000, preventDuplicate: true, })


            })
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    render() {

        const { classes } = this.props;
        return (
            <Grid container spacing={2} alignItems="center" justify='center' className={classes.root}>
                <Helmet>
                    <title>SmartVision | Password Reset</title>
                </Helmet>
                <Grid item xs={12}>
                    <DynamicTypo size="md" variant='h4'> Password Reset </DynamicTypo>
                </Grid>
                <Grid item xs={10} sm={8} md={6} lg={4} >
                    <Grid component="form" container spacing={2} alignItems="center" justify='center' onSubmit={(e) => { e.preventDefault(); this.handleResetPassword(); }} >

                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-password">Password</InputLabel>
                                <Input type="password" id="customer-password" value={this.state.password} onChange={this.handleChange('password')} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="component-confirmed-password">Confirmed Password</InputLabel>
                                <Input type="password" id="customer-confirmed-password" value={this.state.confirmed_password} onChange={this.handleChange('confirmed_password')} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" type='submit'>
                                Change Password
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        );
    }
}



const mapDispatchToProps = dispatch => {
    return {
        handleInitUser: () => dispatch(initUser()),
    }
}


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(withSnackbar(ResetPasswordForm))));